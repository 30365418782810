<template>
    <div>
        <SimpleNotification ref="notification" />
        <img class="logo" src="/river-inmersivo.png" />
        <div class="container">
            <div v-show="showQrCode">
                <div id="qrScannerContainer">

                    <button id="closeQrScanner" @click="showQrCode = !showQrCode">Cerrar</button>
                    <div>
                        <qrcode-stream v-if="loadQrScanner" :track="onTrack" @camera-on="onCamInit"
                            :constraints="selectedConstraints">
                            <div class="square-overlay"></div>
                        </qrcode-stream>
                    </div>
                    <select v-model="selectedConstraints">
                        <option v-for="option in constraintOptions" :key="option.label" :value="option.constraints">
                            {{ option.label }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="draggable-list">
                <draggable v-model="listaJugadores" @start="isDragging = true" @end="onDragEnd"
                    item-key="listaJugadores" group="jugadores">
                    <template #item="{ element, index }">
                        <div class="list-group-item" :data-id="element.id">
                            <div class="list-index">{{ index + 1 }}</div>
                            <div class="list-name" v-if="!element.empty">
                                <div class="avatar">
                                    <img v-if="element.avatar" :src="element.avatar" alt="avatar">
                                </div>
                                <div class="name">
                                    {{ element.username }}
                                </div>
                            </div>
                        </div>
                    </template>
                </draggable>
            </div>
            <draggable class="draggable-list draggable-remove-list" :class="{ dragging: isDragging }"
                v-model="listaBorrar" item-key="listaBorrar" @add="onRemoveAdded" group="jugadores">
                <template #item="{ e }">
                    <div style="display: none;">{{ e }}</div>
                </template>
            </draggable>

            <div id="button-section">
                <button :disabled="DeshabilitarAgregarJugadores" @click="showQrCode = !showQrCode">Escanear QR</button>
                <button :disabled="DeshabilitarAgregarJugadores" @click="AddGuestUser">Agregar Invitado</button>

                <button :disabled="DeshabilitarComenzarJuego" @click="ComenzarJuego">Comenzar Juego</button>
            </div>
            <!-- <div>
                <p>Estado Juego: {{ gameState }}</p>
            </div> -->

            <!-- Lista jugadores de juego en curso -->
            <div v-if="juegoComenzado && listaJugadoresActual.length">
                <h3>EN JUEGO</h3>
                <div class="listaJugadoresActual">
                    <div v-for="j in listaJugadoresActual" :key="j.id">
                        <img v-if="j.avatar" :src="j.avatar" alt="avatar">
                        <div>{{ j.username }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'
import draggable from 'vuedraggable'
import SimpleNotification from './SimpleNotification.vue'
const NotificationAudio = require('@/assets/sounds/message-02.mp3');
const defaultConstraintOptions = [
    { label: 'rear camera', constraints: { facingMode: 'environment' } },
    { label: 'front camera', constraints: { facingMode: 'user' } }
]

export default {
    components: {
        QrcodeStream,
        draggable,
        SimpleNotification,
    },
    props: {
        socket: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            notificationSound: null,
            showQrCode: false,
            camera: null,
            cameras: [],
            constraints: {},
            loadQrScanner: false,
            maxJugadores: 6,
            listaJugadores: [],
            listaJugadoresActual: [],
            listaBorrar: [{}],
            juegoComenzado: false,
            gameState: "",
            isDragging: false,
            fetchingUser: false,
            currQrCode: null,
            selectedConstraints: { facingMode: 'environment' },
            constraintOptions: defaultConstraintOptions
        }
    },
    methods: {
        onRemoveAdded() {
            const removedElement = this.listaBorrar[0];
            const removedPosition = removedElement.position;
            // Add empty element to the list in removed position
            this.listaJugadores.splice(removedPosition, 0, {
                id: `-${removedPosition}`,
                username: ``,
                empty: true,
                position: removedPosition,
                avatar: null,
                guest: false,
            });
            this.listaBorrar = [];
        },
        async onCamInit() {
            // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
            // camera access permission. `QrcodeStream` internally takes care of
            // requesting the permissions. The `camera-on` event should guarantee that this
            // has happened.
            const devices = await navigator.mediaDevices.enumerateDevices()
            const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')

            this.constraintOptions = [
                ...defaultConstraintOptions,
                ...videoDevices.map(({ deviceId, label }) => ({
                    label: `${label}`,
                    constraints: { deviceId }
                }))
            ]
        },
        async onTrack(detectedCodes) {
            // This function will be called for each frame
            // Return an array of detected codes you want to track
            if (!this.showQrCode) return;
            if (!detectedCodes.length) return;

            const detectedID = detectedCodes[0]?.rawValue;
            if (this.currQrCode === detectedID) return;
            this.currQrCode = detectedID;
            console.log("detected QR", detectedID)
            // Parse as json
            let data = JSON.parse(detectedID)
            if (data.token && data.token.length > 0) {
                this.FetchUser(data.token);
            }
        },
        async FetchUser(token) {
            if (this.fetchingUser) return;
            this.fetchingUser = true;
            try {
                // fetch user data using token in header
                const res = await fetch(process.env.VUE_APP_API_URL + "/usuarios", {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })

                if (res.status === 200) {
                    const user = await res.json();
                    console.log("user", user)
                    this.AddUser(user.username, user.id, user.avatar);
                } else {
                    this.$refs.notification.show('Usuario no encontrado', 'error', 3000);
                }
            } catch (e) {
                console.error(e)
            } finally {
                this.showQrCode = false;
                this.fetchingUser = false;
            }
        },
        async selectCamera() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                this.cameras = devices.filter(device => device.kind === 'videoinput');

                // Prioritize front-facing camera on mobile devices
                const frontCamera = this.cameras.find(cam =>
                    cam.label.toLowerCase().includes('front') ||
                    cam.label.toLowerCase().includes('selfie')
                );

                if (frontCamera) {
                    // Use front-facing camera if available
                    this.camera = frontCamera.deviceId;
                } else {
                    // Fallback to previous logic for selecting a webcam
                    const webcam = this.cameras.find(cam => cam.label.toLowerCase().includes('webcam'));

                    if (webcam) {
                        this.camera = webcam.deviceId;
                    } else if (this.cameras.length > 0) {
                        // If no specific webcam is found, use the first available camera
                        this.camera = this.cameras[0].deviceId;
                    }
                }

                // Set the constraints based on the selected camera
                this.constraints = {
                    video: {
                        deviceId: this.camera ? { exact: this.camera } : undefined
                    }
                };

                this.loadQrScanner = true;
            } catch (error) {
                console.error('Error accessing media devices:', error);
            }
        },
        onError(error) {
            console.error('Error accessing media devices:', error)
            if (error.name === 'NotAllowedError') {
                // user denied camera access permission
            } else if (error.name === 'NotFoundError') {
                // no suitable camera device installed
            } else if (error.name === 'NotSupportedError') {
                // page is not served over HTTPS (or localhost)
            } else if (error.name === 'NotReadableError') {
                // maybe camera is already in use
            } else if (error.name === 'OverconstrainedError') {
                // did you request the front camera although there is none?
            } else if (error.name === 'StreamApiNotSupportedError') {
                // browser seems to be lacking features
            }
        },
        AddUser(username, id, avatar = null, guest = false, emit = true) {
            // chequeo que no haya otro con mismo id
            let index = this.listaJugadores.findIndex(j => j.id === id);
            if (index >= 0) {
                this.$refs.notification.show(`Este jugador ya está en fila`, 'error', 3000);
                return;
            }

            let emptyIndex = this.listaJugadores.findIndex(j => j.empty);
            if (emptyIndex >= 0) {
                this.listaJugadores[emptyIndex].username = username;
                this.listaJugadores[emptyIndex].id = id;
                this.listaJugadores[emptyIndex].empty = false;
                this.listaJugadores[emptyIndex].position = emptyIndex;
                this.listaJugadores[emptyIndex].avatar = avatar;
                this.listaJugadores[emptyIndex].guest = guest;
            }

            if (emit) {
                this.EmitPlayerList();
            }
            this.$refs.notification.show(`Hola ${username}!`, 'success', 3000);
            this.notificationSound.volume = 0.3;
            this.notificationSound.play()
        },
        AddGuestUser() {
            const id = Math.floor(Math.random() * 999).toString(); //Math.random().toString(36).substring(3)
            const randomName = "Invitado " + id;
            const avatar = process.env.VUE_APP_BACKEND_HOST + "/guest.png";
            this.AddUser(randomName, id, avatar, true);
        },
        EmitPlayerList() {
            this.AdjustPlayerOrder();
            const players = this.listaJugadores.filter(j => !j.empty)
            this.socket.emit('PlayerList', players);
            // console.log("EmitPlayerList", players)
        },
        RemovePlayer(id) {
            let index = this.listaJugadores.findIndex(j => j.id === id);
            if (index >= 0) {
                this.listaJugadores[index].username = "";
                this.listaJugadores[index].id = -index;
                this.listaJugadores[index].empty = true;
                this.listaJugadores[index].avatar = null;
                this.listaJugadores[index].guest = false;
            }
            this.EmitPlayerList();
        },
        ComenzarJuego() {
            // TODO enviar socket
            // console.log("ComenzarJuego")
            this.juegoComenzado = true;
            this.socket.emit('StartGame');
            // clone lista jugadores
            this.listaJugadoresActual = JSON.parse(JSON.stringify(this.listaJugadores));
            this.listaJugadoresActual = this.listaJugadoresActual.filter(j => !j.empty);
            // clear lista jugadores
            this.listaJugadores.forEach(j => {
                j.username = "";
                j.id = -j.position;
                j.empty = true;
                j.avatar = null;
                j.guest = false;
            });
        },
        onDragEnd() {
            // console.log("onDragEnd", e)
            // const playerId = e.dataset.id;
            // // const oldIndex = e.oldIndex;
            // const newIndex = e.newIndex;
            // TODO enviar toda la lista de nuevo
            // update position
            this.isDragging = false;
            this.EmitPlayerList();
        },
        AdjustPlayerOrder() {
            for (let i = 0; i < this.listaJugadores.length; i++) {
                this.listaJugadores[i].position = i;
            }
        },
        GameFinished() {
            // console.log("GameFinished")
            this.juegoComenzado = false;
            setTimeout(() => {
                if (this.listaJugadores.filter(j => !j.empty).length == 0) return;
                this.EmitPlayerList();
            }, 1000);
        }
    },
    computed: {
        maximoJugadoresAlcanzado() {
            return this.listaJugadores.filter(j => !j.empty).length >= this.maxJugadores;
        },
        DeshabilitarComenzarJuego() {
            if (this.listaJugadores.filter(j => !j.empty).length == 0) return true;
            if (this.juegoComenzado) return true;
            return false;
        },
        DeshabilitarAgregarJugadores() {
            return this.maximoJugadoresAlcanzado;
        }
    },
    mounted() {
        this.selectCamera();
        // Lleno el array de jugadores
        for (let i = 0; i < this.maxJugadores; i++) {
            this.listaJugadores.push({
                id: `-${i}`,
                username: ``,
                empty: true,
                position: i,
                avatar: null,
                guest: false,
            })
        }

        this.socket.on("StartGame", () => {
            // console.log("Received StartGame")
            this.juegoComenzado = true;
        });
        this.socket.on("GameFinished", () => {
            // console.log("GameFinished")
            this.GameFinished();
        });
        this.socket.on("GameState", (state) => {
            // console.log("GameState", state)
            this.gameState = state;
        });
        this.socket.on("PlayerList", (players) => {
            // console.log("PlayerList", players)
            if (!players.length) return
            // primero vacio la lista
            for (let i = 0; i < this.listaJugadores.length; i++) {
                this.listaJugadores[i].username = "";
                this.listaJugadores[i].id = -i;
                this.listaJugadores[i].empty = true;
                this.listaJugadores[i].avatar = null;
                this.listaJugadores[i].guest = false;
            }
            // luego la lleno con los nuevos jugadores
            players.forEach(p => {
                // console.log("PlayerList", p.position, p)
                this.listaJugadores[p.position] = p;
            });
        });

        this.socket.on("MaxPlayers", (maxPlayers) => {
            // console.log("MaxPlayers", maxPlayers)
            this.maxJugadores = maxPlayers;
            this.listaJugadores.splice(0, this.maxJugadores - 1);
            if (this.listaJugadores.length < maxPlayers) {
                for (let i = this.listaJugadores.length - 1; i < maxPlayers - 1; i++) {
                    this.listaJugadores.push({
                        id: `-${i}`,
                        username: ``,
                        empty: true,
                        position: i,
                        avatar: null,
                        guest: false,
                    })
                }
            }
        });

        this.socket.emit("RequestAllData")
        this.notificationSound = new Audio(NotificationAudio);
    }
}
</script>

<style>
:root{
    --altura-item: 70px;
}
</style>
<style scoped>
@import url('../fonts/stylesheet.css');

#qrScannerContainer {
    width: 100%;
    height: 100%;
    position: fixed; /* Changed from absolute for better mobile handling */
    top: 0;
    left: 0;
    background-color: #000;
    display: -webkit-flex; /* Added vendor prefixes */
    display: -moz-flex;
    display: flex;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    z-index: 9999;
}

#closeQrScanner {
    position: fixed; /* Changed from absolute for better mobile support */
    bottom: 40px;
    left: 50%;
    height: 50px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 300px;
    max-width: 90%; /* Added max-width for smaller screens */
    z-index: 10000;
}

.draggable-list {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(231, 231, 231, 0.5); /* Added transparency for better visual */
    border-radius: 5px;
    margin-bottom: 20px;
    overflow-y: auto;
    background: #212129;
    -webkit-overflow-scrolling: touch; /* Better scroll on iOS */
}

.draggable-list .desactivado {
    pointer-events: none;
    opacity: 0.4;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.draggable-remove-list {
    opacity: 0;
    border: 1px solid #dc362e;
    background-color: rgba(248, 212, 211, 0.9);
    position: relative;
    overflow: hidden;
    min-height: 20px; /* Added minimum height */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.draggable-remove-list::before {
    content: "ELIMINAR";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 20px;
    color: #dc362e;
    white-space: nowrap;
}

.draggable-remove-list.dragging {
    opacity: 1;
}

.list-group-item {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    height: var(--altura-item);
    margin-bottom: 5px;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.list-group-item .list-index {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    min-width: 35px;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    -moz-justify-content: center;
    justify-content: center;
    margin-right: 5px;
    background-color: #000;
    color: #fff;
}

.list-group-item .list-name {
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    -moz-justify-content: space-between;
    justify-content: space-between;
    padding-right: 10px;
    background-color: #000;
    margin-right: 5px;
    overflow: hidden; /* Prevent content overflow */
}

.list-group-item .list-name .avatar {
    height: 100%;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-align-items: center;
    -moz-align-items: center;
    align-items: center;
}

.list-group-item .list-name .avatar img {
    height: var(--altura-item);
    width: auto;
    object-fit: contain;
}

.list-group-item .list-name .name {
    -webkit-flex: 1;
    -moz-flex: 1;
    flex: 1;
    text-align: center;
    padding: 0 10px;
    word-break: break-word; /* Handle long names */
}

.not-draggable {
    cursor: no-drop;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

#button-section {
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    flex-direction: column;
    width: 250px;
    max-width: 100%; /* Better mobile support */
    margin: 0 auto;
    padding: 0 10px; /* Added padding for mobile */
}

#button-section button {
    margin: 10px 0;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: 50px;
    -webkit-appearance: none; /* Fix iOS button styling */
    -moz-appearance: none;
    appearance: none;
    background-color: #333;
    color: white;
    transition: all 0.3s ease;
}

#button-section button:disabled {
    background-color: rgba(204, 204, 204, 0.5);
    color: rgba(102, 102, 102, 0.8);
    cursor: not-allowed;
}

.square-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 70%;
    height: 70%;
    max-width: 300px;
    max-height: 300px;
    aspect-ratio: 1 / 1;
}

.square-overlay::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    --b: 5px;
    --c: #ec192e;
    --w: 15%;
    border: var(--b) solid transparent;
    background: 
        conic-gradient(from 90deg at top var(--b) left var(--b), transparent 90deg, var(--c) 0) 0 0 / var(--w) var(--w) border-box no-repeat,
        conic-gradient(from 180deg at top var(--b) right var(--b), transparent 90deg, var(--c) 0) 100% 0 / var(--w) var(--w) border-box no-repeat,
        conic-gradient(from 0deg at bottom var(--b) left var(--b), transparent 90deg, var(--c) 0) 0 100% / var(--w) var(--w) border-box no-repeat,
        conic-gradient(from -90deg at bottom var(--b) right var(--b), transparent 90deg, var(--c) 0) 100% 100% / var(--w) var(--w) border-box no-repeat;
}

select {
    margin-top: 10px;
    color: inherit;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
    background: #000;
    font-size: 14px;
    width: 200px;
    max-width: 100%;
    display: none;
}

/* Responsive styles */
@media (min-width: 1024px) {
    select {
        display: block;
    }
}

/* Mobile optimization */
@media (max-width: 768px) {
    .list-group-item {
        height: 60px;
    }
    
    #button-section {
        width: 100%;
    }
    
    .square-overlay {
        width: 90%;
    }
}

/* Handle older browsers */
@supports not (aspect-ratio: 1 / 1) {
    .square-overlay {
        height: 0;
        padding-bottom: 70%;
    }
}
</style>